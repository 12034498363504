import axios from "axios";
import managerReports from "../config/reports";
import doctorReports from "../config/doctorsReports";

const getReport = async (startDate, endDate, needCache, report, count = 0) => {
    try {
        const response = await axios.get(process.env.REACT_APP_REPORT_URL, {
            params: {
                dateStart: startDate,
                dateEnd: endDate,
                type: report.type,
                cached: needCache ? 'Y' : 'N',
                inMongo: 'Y'
            }
        });
        if (response.status === 200) {
            const result = response.data;

            if (Array.isArray(result.result)) {
                return result.result.map((res) => ({
                    id: report.id,
                    label: report.label,
                    type: report.type,
                    date: result.reportCreated,
                    data: res
                }));
            }

            return {
                id: report.id,
                label: report.label,
                type: report.type,
                date: result.reportCreated,
                data: result.result
            };
        }

        count++;
        if (count < 2) {
            return getReport(report, count);
        } else {
            return null;
        }
    } catch (e) {
        count++;
        if (count < 2) {
            return getReport(report, count);
        } else {
            return null;
        }
    }
};

export const getReportTitleByCode = (code) => {
    const report =  getReportByCode(code);
    return report ? report.text : '';
}

export const getReportPartTitleByCode = (code, part) => {
    const report =  getReportByCode(code);
    return report ? (part === 'start' ? report.titles.first : report.titles.second) : '';
}

const getReportByCode = (code) => {
    const reports = [].concat(managerReports, doctorReports);
    return reports.find((rep) => rep.type === code);
}

export default getReport;