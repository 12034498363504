import React, {Fragment, useState} from "react";
import Paper from "@material-ui/core/Paper";
import DashboardTile from "../ui/DasboardTile";
import dashboards from "../config/dashboards";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import UsersTable from "../ui/UsersTable";
import Number from "../ui/Number";
import _ from "lodash";

const useStyles = makeStyles({
    dashboard: {
        marginBottom: '40px'
    },
    firstDashboard: {
        marginTop: '20px'
    },
    dashboardsWrapper: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        columnGap: "50px",
        paddingTop: '20px'
    },
    wrapper: {
        padding: '20px',
        position: "relative"
    },
    period: {
        position: "absolute",
        right: "20px"
    }
});

const Dashboards = () => {
    const classes = useStyles();

    const handleClick = ({users: rawUsers, title}) => {
        const users = _.cloneDeep(rawUsers);
        if(Array.isArray(users)) {
            setUsers(users);
        } else {
            const userProcessed = [];
            const usersOb = {};
            for(let key in users) {
                if(users.hasOwnProperty(key)) {
                    const curUsers = users[key];
                    curUsers.forEach((user) => {
                        if(usersOb.hasOwnProperty(user.id)) {
                            usersOb[user.id][key] = user.quantity;
                        } else {
                            user[key] = user.quantity;
                            usersOb[user.id] = user;
                        }
                    })
                }
            }

            for(let key in usersOb) {
                if(usersOb.hasOwnProperty(key)) {
                   userProcessed.push(usersOb[key]);
                }
            }

            userProcessed.map((user) => {
                user.quantity = <div className="Numbers Numbers_Table">
                    <Number val={user.today ? user.today : 0} prev={user.yesterday ? user.yesterday : 0} hint="сегодня"/>
                    <Number val={user.curWeek ? user.curWeek : 0} prev={user.lastWeek ? user.lastWeek : 0} hint="7 дн"/>
                    <Number val={user.curMonth ? user.curMonth : 0} prev={user.lastMonth ? user.lastMonth : 0} hint="текущий месяц"/>
                </div>;
                return user;
            });

            setUsers(userProcessed)
        }

        setSelectedTitle(title);
    };

    const [users, setUsers] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);

    return <div className="">
        <Paper className={classes.wrapper}>
            {dashboards.map((dashboard, index) => {
                const classesDashboard = [classes.dashboard];
                if(index === 0) {
                    classesDashboard.push(classes.firstDashboard);
                }
                return (
                    <div className={classesDashboard.join(' ')} key={index}>
                        <Typography variant="h4">{dashboard.title}</Typography>
                        <div className={classes.dashboardsWrapper}>
                                {
                                    dashboard.dashboards.map((item) => (
                                        <div key={item.code} className={classes.dashboardRow}>
                                            <DashboardTile title={item.title}
                                                           departments={dashboard.departments}
                                                           handleClick={handleClick}
                                                           code={item.code}
                                                           isPeriod={item.isPeriod}/>
                                        </div>
                                    ))
                                }
                        </div>
                    </div>
                )
            })}

            {selectedTitle ? <Fragment>
                <Typography variant="h5">Распределение по менеджерам ({selectedTitle})</Typography>
                <UsersTable users={users} />
            </Fragment> : null}
        </Paper>
    </div>
};

export default Dashboards;