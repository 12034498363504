import React, {Fragment, useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import getReport from "../services/getReport";
import moment from "moment";
import BX24 from "../bx24";
import Number from "./Number";

const useStyles = makeStyles((theme) => ({
    tile: {
        height: '100%',
        cursor: 'pointer',
        minWidth: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    title: {
        paddingBottom: 0
    },
    periodLabel: {
        display: "inline-block",
        borderBottom: "1px dashed",
        "&:hover": {
            borderBottom: "none"
        }
    }
}));

const DashboardTile = (props) => {
    const [fetched, setFetched] = useState(false);
    const [value, setValue] = useState(null);
    const [responsibles, setResponsibles] = useState(null);
    const departments = props.departments;

    const daysFromMonthBeginning = moment().diff(moment().startOf('month'), 'days');
    const previousMonthStart = moment().subtract(1, 'month').startOf('month');
    const dates = [
        {
            current: {
                start: moment().startOf('day').format('YYYY-MM-DD'),
                end: moment().endOf('day').format('YYYY-MM-DD')
            },
            prev: {
                start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')
            }
        },
        {
            current: {
                start: moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD')
            },
            prev: {
                start: moment().subtract(13, 'days').startOf('day').format('YYYY-MM-DD'),
                end: moment().subtract(7, 'days').endOf('day').format('YYYY-MM-DD')
            }
        },
        {
            current: {
                start: moment().startOf('month').format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD')
            },
            prev: {
                start: previousMonthStart.format('YYYY-MM-DD'),
                end: previousMonthStart.add(daysFromMonthBeginning, 'days').format('YYYY-MM-DD')
            }
        }
    ];

    const getUsersData = (users) => {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            BX24.callMethod('user.get', {id: users}, function (res) {
                const data = res.data();
                // eslint-disable-next-line no-undef
                BX24.callMethod('department.get', {}, (result) => {
                    const departments = result.data().reduce((res, department) => {
                        res[+department.ID] = { title: department.NAME };
                        return res;
                    } , {});

                    const users = data.map((user) => {
                        const userDepartment = user.UF_DEPARTMENT.filter((depId) => !!departments[+depId]).shift();
                        if(userDepartment && departments.hasOwnProperty(userDepartment)) {
                            user.department = departments[userDepartment].title;
                        }

                        return user;
                    }, {});

                    resolve(users);
                });
            });
        })
    };

    const getReportResponsibles = (startDate, endDate) => {
        return new Promise((resolve) => {
            getReport(startDate, endDate, false, {type: props.code}, 0, true)
                .then(async (data) => {
                    const arData = data.data.data;
                    const users = [];
                    for(let id in arData.responsibles) {
                        if(arData.responsibles.hasOwnProperty(id)) {
                            users.push(id);
                        }
                    }

                    let usersData = await getUsersData(users);
                    usersData = usersData
                        .filter((userData) => departments.map((depId) => userData.UF_DEPARTMENT.includes(depId))
                            .filter(isReportDep => !!isReportDep).length > 0);
                    const responsibles = [];
                    for(let id in arData.responsibles) {
                        if(arData.responsibles.hasOwnProperty(id)) {
                            const quantity = arData.responsibles[id];
                            const user = usersData.find((user) => +user.ID === +id);
                            if(user) {
                                responsibles.push({id: user.ID, name: user.LAST_NAME + ' ' + user.NAME, department: user.department, quantity: quantity, photo: user.PERSONAL_PHOTO});
                            }
                        }
                    }

                    resolve({responsibles: responsibles, total: responsibles.reduce((res, responsible) => res + responsible.quantity, 0)});
                });
        })
    };

    useEffect(() => {
        if (!fetched && props.isPeriod) {
            setFetched(false);
            Promise.all(dates.reduce((res, {current, prev}) => {
                res.push(getReportResponsibles(current.start, current.end));
                res.push(getReportResponsibles(prev.start, prev.end));
                return res;
            }, [])).then((data) => {
                const [today, yesterday, curWeek, lastWeek, curMonth, lastMonth] = data;
                setResponsibles({
                    today: today.responsibles,
                    yesterday: yesterday.responsibles,
                    curWeek: curWeek.responsibles,
                    lastWeek: lastWeek.responsibles,
                    curMonth: curMonth.responsibles,
                    lastMonth: lastMonth.responsibles
                });

                setValue({
                    today: today.total,
                    yesterday: yesterday.total,
                    curWeek: curWeek.total,
                    lastWeek: lastWeek.total,
                    curMonth: curMonth.total,
                    lastMonth: lastMonth.total
                });

                setFetched(true);
            });
        } else if(!fetched) {
            setFetched(false);
            const date = {
                start: moment().startOf('day').format('YYYY-MM-DD'),
                end: moment().endOf('day').format('YYYY-MM-DD')
            };

            getReportResponsibles(date.start, date.end).then(({responsibles, total}) => {
                setValue(total);
                setResponsibles(responsibles);
                setFetched(true);
            });
        }
    }, [props.code, props.startDate, props.endDate]);

    const classes = useStyles();
    const [isRaised, setRaised] = useState(false);

    return <Card onMouseEnter={() => {setRaised(true)}}
                 onMouseLeave={() => {setRaised(false)}}
                 onClick={() => {props.handleClick({users: responsibles, title: props.title})}}
                 raised={isRaised}
                 className={classes.tile}>
        <CardHeader className={classes.title} titleTypographyProps={{variant: 'body2'}} title={props.title}/>
        <CardContent>
            {fetched ? (
                <Fragment>
                    <Typography align="center" variant="h4" component="div" gutterBottom>{
                        (props.isPeriod ? <Fragment>
                            <div className="Numbers">
                                <Number val={value.today} prev={value.yesterday} hint="сегодня"/>
                                <Number val={value.curWeek} prev={value.lastWeek} hint="7 дн"/>
                                <Number val={value.curMonth} prev={value.lastMonth} hint="текущий месяц"/>
                            </div>
                        </Fragment> : value)
                    }</Typography>
                </Fragment>
            ) : <Box style={{textAlign: "center"}}><CircularProgress/></Box>}

        </CardContent>
    </Card>
};

export default DashboardTile;