import React, {Fragment, useState} from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import Reports from "../pages/Reports";
import Dashboards from "../pages/Dashboards";
import TabPanel from "../ui/TabPanel";
import moment from "moment";

import reports from "../config/reports";
import docotorReports from "../config/doctorsReports";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Default = (props) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Fragment>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Отчеты" {...a11yProps(0)} />
                    <Tab label="Дашборды" {...a11yProps(1)} />
                    <Tab label="Отчеты по врачам" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Reports minPossibleDate={moment([2021, 2, 1])} reports={reports} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Dashboards />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Reports minPossibleDate={moment([2021, 5, 1])} reports={docotorReports}/>
            </TabPanel>
        </Fragment>
    );
}

export default Default;