import {createStaticRanges} from "react-date-range";
import moment from "moment";

const ranges = [
    {
        label: 'Текущий месяц',
        range: () => ({
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate()
        })
    },
    {
        label: 'Предыдущий месяц',
        range: () => ({
            startDate: moment().subtract(1, 'month').startOf('month').toDate(),
            endDate: moment().subtract(1, 'month').endOf('month').toDate()
        })
    },
    {
        label: 'Последние 7 дней',
        range: () => ({
            startDate: moment().subtract(6, 'days').startOf('day').toDate(),
            endDate: moment().startOf('day').toDate()
        })
    }
];

export const rangesDashboards = createStaticRanges([
    {
        label: 'Текущий день',
        range: () => ({
            startDate: moment().startOf('day').toDate(),
            endDate: moment().endOf('day').toDate()
        }),
        prevRange: () => ({
            startDate: moment().subtract(1, 'days').startOf('day').toDate(),
            endDate: moment().subtract(1, 'days').endOf('day').toDate()
        }),
    },
    {
        label: 'Последние 7 дней',
        range: () => ({
            startDate: moment().subtract(6, 'days').startOf('day').toDate(),
            endDate: moment().startOf('day').toDate()
        }),
        prevRange: () => ({
            startDate: moment().subtract(13, 'days').startOf('day').toDate(),
            endDate: moment().subtract(7, 'days').endOf('day').toDate()
        })
    },
    {
        label: 'Текущий месяц',
        range: () => ({
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate()
        }),
        prevRange: () => ({
            startDate: moment().subtract(1, 'month').startOf('month').toDate(),
            endDate: moment().subtract(1, 'month').endOf('month').toDate()
        })
    }
]);


const staticRanges = createStaticRanges(ranges);
export default staticRanges

export const getStaticRanges = (date) => {
    return createStaticRanges(ranges)
        .filter((range) => range.range().startDate >= date)
};
