export default [
    {
        id: 1,
        type: 'PREP',
        text: 'Соотношение количества новых сделок к количеству записей на прием',
        titles: {first: 'Новые сделки', second: 'Записи на прием'}
    },
    {
        id: 2,
        type: 'PREP_WON',
        text: 'Соотношение количества записей на прием к количеству состоявшихся консультаций',
        titles: {first: 'Записи на прием', second: 'Состоявшиеся консультации'}
    },
    {
        id: 3,
        type: 'WON_COMPLEX',
        text: 'Соотношение количества новых сделок к количеству состоявшихся консультаций',
        titles: {first: 'Новые сделки', second: 'Состоявшиеся консультации'},
        children: [
            { text: 'Консультация' },
            { text: 'Онлайн-консультация' },
        ]
    },
    {
        id: 5,
        type: 'C1:WON',
        text: "Соотношение количества состоявшихся консультаций к количеству заключение договора",
        children: [
            { text: "Все" },
            { text: "Первичка" },
            { text: "Вторичка" }
        ],
        titles: {first: 'Состоявшиеся консультации', second: 'Заключенные договоры'},
    },
    {
        id: 6,
        type: 'SUCESSFULL_LEAD_PERV:CONSULTED',
        text: "Соотношение первичных лидов к состоявшимся консультациям",
        children: [
            { text: "Консультация" },
            { text: "Онлайн-консультация" }
        ],
        titles: {first: 'Первичные лиды', second: 'Состоявшиеся консультации'},
    }
]