import React, {useState, useRef} from 'react';
import BX24 from "../bx24";
import {
    Paper,
    TableContainer,
    Button,
    Select,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Popover,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    makeStyles
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import '../App.css';

import moment from 'moment';
import Report from "../components/report_new";
import getReportDefault from "../services/getReport";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {DateRangePicker} from 'react-date-range';
import {ru} from 'react-date-range/dist/locale'
import {getStaticRanges} from '../config/ranges';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    periodWrapper: {
        padding: 10,
        alignItems: 'center'
    },
    period: {
        width: 300
    },
    input: {
        marginRight: 50,
        alignSelf: 'flex-start'
    },
    input_center: {
        alignSelf: 'center'
    },
    input_size_m: {
        width: 300
    }
});


function Reports(props) {
    const staticRanges = getStaticRanges(props.minPossibleDate.toDate());
    const classes = useStyles();
    const [cached, setNeedCached] = useState(true);

    const [selectedReport, selectReport] = useState(props.reports.length === 1 ? props.reports[0] : null);
    const [titles, setTitles] = useState({first: '', second: ''});
    const [reportType, setReportType] = useState({first: '', second: ''});
    const handleReportChange = (ev) => {
        const selectedType = ev.target.value;
        if (selectedType === 'EMPTY') {
            selectReport(null);
        } else {
            props.reports.forEach((report) => {
                if (report.type === selectedType) {
                    selectReport(report);
                }
            })
        }
    };

    const [reportData, setReportData] = useState(null);
    const [reportTitle, setReportTitle] = useState(null);

    const getReport = async (report, count) => {
        const startDate = moment(selection.startDate).format('YYYY-MM-DD');
        const endDate = moment(selection.endDate).format('YYYY-MM-DD');
        return getReportDefault(startDate, endDate, cached, report, count);
    };

    const fetchReport = async () => {
        setFetchingIsGoing(true);
        const data = await getReport(selectedReport, 0);
        if (Array.isArray(data)) {
            setReportData(data.map((datum, index) => ({...datum, title: selectedReport.children[index].text})));
        } else {
            setReportData({...data, title: selectedReport.text});
        }
        setReportTitle(selectedReport.text);
        setTitles({...selectedReport.titles});
        setReportType(selectedReport.type);
        setNeedCached(true);
        setFetchingIsGoing(false);
    };


    const handleClickCustomInterval = () => {
        setSelectVal('custom');
        setAnchorEl(el.current)
    };
    const handlePeriodChange = (ev) => {
        const val = ev.target.value;
        if (val !== 'custom') {
            const {startDate, endDate} = JSON.parse(val);
            setSelection({startDate: new Date(startDate), endDate: new Date(endDate), key: 'selection'});
            setSelectVal(JSON.stringify({startDate: new Date(startDate), endDate: new Date(endDate)}));
        }
    };

    const initial = moment().subtract(1, 'months').endOf('month') >= props.minPossibleDate ? {
        startDate: moment().subtract(1, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate()
    } : {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
    };

    const [intervalSelectVal, setSelectVal] = useState(JSON.stringify(initial));

    const [selection, setSelection] = useState({
        ...initial,
        key: 'selection',
    });

    const handleSelect = (ranges) => {
        setSelection(ranges.selection);
    };

    const el = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [fetchingIsGoing, setFetchingIsGoing] = useState(false);
    // const [dataFulfilled, setDataFulfilled] = useState(false);
    const dataFulfilled = selectedReport !== null;

    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleNumberClick = (userId, startOrEnd, depOrUser, report, data) => {
        if (report === 'DOCTORS:CONSULT_TO_CONTRACT' && depOrUser === 'user') {
            BX24.openApplication({
                    bx24_width: 800,
                    bx24_title: "Отчеты: Подробно",
                    bx24_label: {
                        'bgColor': '#00a3ea',
                        'text': 'Отчеты',
                        'color': '#ffffff',
                    },
                    parameters: {
                        userId: userId,
                        type: startOrEnd,
                        report: report,
                        data: data
                    }
                }
            );
        }
    }
    const hasDetailInfo = (report) => {
        return report === 'DOCTORS:CONSULT_TO_CONTRACT'
    }

    return (
        <div className="App">
            <TableContainer component={Paper}>
                <FormGroup className={classes.periodWrapper} row>
                    <div className={[classes.input].join(' ')}>
                        <FormControl className={classes.period} ref={el}>
                            <InputLabel>Выберте период</InputLabel>
                            <Select disabled={fetchingIsGoing} value={intervalSelectVal} onChange={handlePeriodChange}>
                                {staticRanges.map(range => {
                                    const val = JSON.stringify(range.range());
                                    return <MenuItem key={val} value={val}>{range.label}</MenuItem>
                                })}
                                <MenuItem onClick={handleClickCustomInterval} key="custom" value="custom">Произвольный
                                    интервал</MenuItem>
                            </Select>
                            <FormHelperText style={{fontSize: '0.9rem', color: '#555'}}>
                                <b>{selection.startDate.toLocaleDateString()}</b> - <b>{selection.endDate.toLocaleDateString()}</b>
                            </FormHelperText>
                        </FormControl>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <DateRangePicker
                                disabled={fetchingIsGoing}
                                // minDate={new Date(2020, 9, 1)}
                                minDate={props.minPossibleDate.toDate()}
                                maxDate={moment().endOf('month').toDate()}
                                showDateDisplay={true}
                                showPreview={false}
                                staticRanges={staticRanges}
                                inputRanges={[]}
                                locale={ru}
                                onChange={handleSelect}
                                ranges={[selection]}/>
                        </Popover>
                    </div>
                    <FormControl className={[classes.input, classes.input_size_m].join(' ')}>
                        <InputLabel>Выберте отчет</InputLabel>
                        <Select
                            disabled={fetchingIsGoing}
                            value={selectedReport ? selectedReport.type : 'EMPTY'}
                            onChange={handleReportChange}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                        >
                            <MenuItem value={'EMPTY'}>Выберите отчет...</MenuItem>
                            {props.reports.map((report) => <MenuItem key={report.type}
                                                                     value={report.type}>{report.text}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        disabled={fetchingIsGoing}
                        className={[classes.input, classes.input_center].join(' ')}
                        control={<Checkbox color="primary" disabled={fetchingIsGoing} checked={cached}
                                           onChange={(ev) => {
                                               setNeedCached(ev.currentTarget.checked)
                                           }} name="useCache"/>}
                        label="Использовать кэш"
                    />
                    <Button disabled={fetchingIsGoing || !dataFulfilled} variant="contained" color="primary"
                            onClick={fetchReport}>
                        Получить отчет
                        {fetchingIsGoing ?
                            <CircularProgress style={{marginLeft: 10, color: "white"}} size="20px"/> : null}
                    </Button>
                </FormGroup>
                {
                    reportData && !fetchingIsGoing ? (
                        <Box ml={2} mt={3}>
                            <Typography variant="h6" gutterBottom component="div">
                                {reportTitle} {Array.isArray(reportData) && reportData[value] ? ` (${reportData[value].title})` : null}
                            </Typography>
                            <Typography gutterBottom component="div">
                                Дата составления
                                отчета: {Array.isArray(reportData) ? reportData[value].date : reportData.date}
                            </Typography>
                        </Box>
                    ) : null
                }

                {reportData && !fetchingIsGoing ? (
                    Array.isArray(reportData) ? (
                        <div>
                            <AppBar position="static">
                                <Tabs value={value} onChange={handleTabChange}>
                                    {reportData.map(reportDatum => <Tab key={reportDatum.key}
                                                                        label={reportDatum.title}/>)}
                                </Tabs>
                            </AppBar>
                            <TabContext value={value}>
                                {reportData.map((reportDatum, index) => (
                                    <TabPanel key={reportDatum.key} value={index}>
                                        <Report numberClicked={handleNumberClick}
                                                detail={hasDetailInfo(reportType)}
                                                type={reportType}
                                                index={index}
                                                titles={titles}
                                                title={reportTitle}
                                                data={reportDatum}/>
                                    </TabPanel>
                                ))}
                            </TabContext>
                        </div>
                    ) : <Report detail={hasDetailInfo(reportType)}
                                numberClicked={handleNumberClick}
                                type={reportType}
                                index={0}
                                titles={titles}
                                title={reportTitle}
                                data={reportData}/>
                ) : (fetchingIsGoing ? <Skeleton variant="rect" width="100%" height={100}/> : null)}

            </TableContainer>
        </div>
    );
}

export default Reports;
