const dashboards = [
    {
        title: 'Лидорубы',
        dashboards: [
            { title: 'Открытые сделки в воронке "Консультация"', code: 'DASHBOARD:CONSULTATION:OPENED', isPeriod: false },
            { title: 'Новые сделки в воронке "Консультация"', code: 'DASHBOARD:CONSULTATION:NEW', isPeriod: true },
            { title: 'Назначено консультаций', code: 'DASHBOARD:CONSULTATION:ASSIGNED', isPeriod: true },
            { title: 'Проведено консультаций', code: 'DASHBOARD:CONSULTATION:HAPPENED', isPeriod: true },
        ],
        departments: [ 61 ]
    },
    {
        title: 'Менеджеры',
        dashboards: [
            { title: 'Открытые сделки в воронке "Заключение договора"', code: 'DASHBOARD:CONTRACT:OPENED', isPeriod: false },
            { title: 'Новые сделки в воронке "Заключение договора"', code: 'DASHBOARD:CONTRACT:NEW', isPeriod: true },
            { title: 'Открытые сделки в воронке "Лечение"', code: 'DASHBOARD:CURE:OPENED', isPeriod: false },
        ],
        departments: [ 59 ]
    }
];

export default dashboards;