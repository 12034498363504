import React, {Fragment, useEffect, useState} from "react";
import {TableCell, TableRow, Table, TableHead, TableBody} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BX24 from '../bx24.js';
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";

const departmentsForReports = [5, 61, 59];

const useStyles = makeStyles((theme) => ({
    department: {
        background: '#eeeeee4d'
    },
    departmentTitle: {
        fontWeight: 'bold'
    },
    detailLabel: {
        cursor: 'pointer',
        fontWeight: '500',
        color: theme.palette.text.primary,
        '&:hover': {
            textDecoration: "underline"
        }
    }
}));

const Report = (props) => {
    const classes = useStyles();
    const [usersData, setUsers] = useState([]);

    const data = props.data;
    const reportType = props.type;
    const reportIndex = props.index;
    const first = data.data.first;
    const second = data.data.second;
    const reports = new Map();

    for (let userId in first) {
        if (first.hasOwnProperty(userId)) {
            reports.set(userId, {
                userId: userId,
                start: first[userId],
                end: 0
            });
        }
    }

    for (let userId in second) {
        if (second.hasOwnProperty(userId)) {
            if (reports.has(userId)) {
                reports.set(userId, {
                    ...reports.get(userId),
                    end: second[userId],
                    conversion: (Number(second[userId]) / reports.get(userId).start * 100).toFixed(1)
                });
            } else {
                reports.set(userId, {
                    userId: userId,
                    start: 0,
                    end: second[userId],
                    conversion: null
                });
            }
        }
    }

    const reps = [...reports.values()];
    const users = [...reports.keys()];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(async () => {
        //additional information
        let firstAdd = {};
        let firstAddPlus = {};
        let secondAdd = {};
        let secondAddPlus = {};
        let isStartAdditional = false;

        //Дополнительные данные по отчетам
        if(['PREP_WON/0', 'WON_COMPLEX/0', 'C1:WON/0', 'C1:WON/1', 'C1:WON/2', 'SUCESSFULL_LEAD_PERV:CONSULTED/0'].includes(reportType + '/' + reportIndex)) {
            switch (reportType) {
                case 'C1:WON':
                    isStartAdditional = true;
                    break;
                default:
                    isStartAdditional = false;
            }

            //1) получить из всего списка сделок те, у которых есть родительская сделка. (запомнить ответственного)
            const dealsWithParentPromise = new  Promise((resolve, reject) => {
                // eslint-disable-next-line
                BX24.callMethod('crm.deal.list', {
                    filter: {
                        ID: isStartAdditional ? Object.values(data.data.start_deals) : Object.values(data.data.end_deals),
                        '>UF_CRM_PARENT_DEAL': 0
                    },
                    select: ['ID', 'ASSIGNED_BY_ID', 'UF_CRM_PARENT_DEAL']
                }, (res) => {
                    const deals = res.data();
                    resolve(deals);
                });
            });
            const dealsWithParent = await dealsWithParentPromise;

            //2) получить из списка родительских сделок только успешные в воронке онлайн-консультации (запомнить ответсвтенного) запишется как (+Х)
            const dealsOnlineConsultPromise = new  Promise((resolve, reject) => {
                // eslint-disable-next-line
                BX24.callMethod('crm.deal.list', {
                    filter: {
                        ID: dealsWithParent.map((deal) => deal.UF_CRM_PARENT_DEAL),
                        'CATEGORY_ID': '7',
                        'STAGE_SEMANTIC_ID': "S"
                    },
                    select: ['ID', 'CATEGORY_ID', 'ASSIGNED_BY_ID', 'UF_CRM_CHILD_DEAL', 'STAGE_SEMANTIC_ID']
                }, (res) => {
                    const deals = res.data();
                    resolve(deals);
                });
            });
            const dealsOnlineConsult = await dealsOnlineConsultPromise;
            const dealsOnlineConsultId = dealsOnlineConsult.map((deal) => +deal.ID);

            //3) в сделках полученные в п.1. оставить только те, родители которых удовлетворяют условию п. 2
            const dealsWithParentApproved = dealsWithParent.filter((dealWithParent) => {
                return dealsOnlineConsultId.includes(+dealWithParent.UF_CRM_PARENT_DEAL);
            });

            dealsWithParentApproved.forEach((dealWithParentApproved) => {
                const assignedById = Number(dealWithParentApproved.ASSIGNED_BY_ID);
                let additionalInfo = isStartAdditional ? firstAdd : secondAdd;
                if(!additionalInfo.hasOwnProperty(assignedById)) {
                    additionalInfo[assignedById] = 0;
                }
                additionalInfo[assignedById] += 1;
            });

            dealsOnlineConsult.forEach((dealOnlineConsult) => {
                const assignedById = Number(dealOnlineConsult.ASSIGNED_BY_ID);
                let additionalInfo = isStartAdditional ? firstAddPlus : secondAddPlus;
                if(!additionalInfo.hasOwnProperty(assignedById)) {
                    additionalInfo[assignedById] = 0;
                }
                additionalInfo[assignedById] += 1;
            })

        }

        // eslint-disable-next-line no-undef
        BX24.callMethod('user.get', {id: users}, function (res) {
            const data = res.data();
            // eslint-disable-next-line no-undef
            BX24.callMethod('department.get', {}, (result) => {
                const departments = result.data().reduce((res, department) => {
                    if(departmentsForReports.includes(Number(department.ID)) || props.type.indexOf('DOCTOR') === 0) {
                        res[Number(department.ID)] = { title: department.NAME, start: 0, end: 0, conversion: 0, users: [] };
                    }
                    return res;
                } , {});

                const total = { start: 0, end: 0, conversion: null, title: 'Итого' };
                data.forEach((user) => {
                    const userDepartment = user.UF_DEPARTMENT.filter((depId) => !!departments[Number(depId)]).shift();
                    if(userDepartment && departments.hasOwnProperty(userDepartment)) {
                        const rep = reps.find(rep => Number(rep.userId) === Number(user.ID));
                        const userId = Number(user.ID);
                        departments[userDepartment].start += rep.start;
                        departments[userDepartment].end += rep.end;
                        departments[userDepartment].conversion = departments[userDepartment].end ? (departments[userDepartment].end / departments[userDepartment].start * 100).toFixed(1) : null;
                        departments[userDepartment].users.push({
                            id: user.ID,
                            name: user.NAME,
                            lastName: user.LAST_NAME,
                            avatar: user.PERSONAL_PHOTO,
                            department: departments[userDepartment].title,
                            start: rep.start,
                            startAdd: isStartAdditional && firstAdd.hasOwnProperty(userId) ? firstAdd[userId] : 0,
                            startAddPlus: isStartAdditional && firstAddPlus.hasOwnProperty(userId) ? firstAddPlus[userId] : 0,
                            end: rep.end,
                            endAdd: !isStartAdditional && secondAdd.hasOwnProperty(userId) ? secondAdd[userId] : 0,
                            endAddPlus: !isStartAdditional && secondAddPlus.hasOwnProperty(userId) ? secondAddPlus[userId] : 0,
                            conversion: rep.conversion,
                            isDepartment: false
                        });

                        total.start += rep.start;
                        total.end += rep.end;
                        total.conversion = total.end ? (total.end / total.start * 100).toFixed(1) : null;
                    }
                }, {});

                const users = [];
                for(let dep in departments) {
                    if(departments.hasOwnProperty(dep)) {
                        if(departments[dep].users.length > 0) {
                            users.push({
                                start: departments[dep].start,
                                end: departments[dep].end,
                                conversion: departments[dep].conversion,
                                title: departments[dep].title,
                                isDepartment: true
                            })
                        }

                        departments[dep].users.forEach((user) => {
                            users.push(user);
                        });
                    }
                }

                users.push({
                    start: total.start,
                    end: total.end,
                    conversion: total.conversion,
                    title: total.title,
                    isDepartment: true,
                });

                setUsers(users);
            });
        });
    }, []);

    const clickNumber = (userId, startOrEnd, isDep) => {
        props.numberClicked(userId, startOrEnd, isDep ? 'department' : 'user', props.type, props.data);
    }
    const numberClass = props.detail ? classes.detailLabel : '';

    return (
        <Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Сотрудник</TableCell>
                        <TableCell>{props.titles ? props.titles.first : ''}</TableCell>
                        <TableCell>{props.titles ? props.titles.second : ''}</TableCell>
                        <TableCell>Соотношение, %</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { usersData.length > 0 ? usersData.map((user) => (
                        <TableRow className={user.isDepartment ? classes.department : ''} key={users.id}>
                            <TableCell>
                                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                    { user.isDepartment ? null : <Avatar src={user.avatar}/> }
                                    <Box ml={ user.isDepartment ? 0 : 1 }>
                                        <Typography>
                                            {user.isDepartment ? <Typography className={classes.departmentTitle}>{user.title}</Typography> : `${user.lastName} ${user.name}`}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </TableCell>
                            <TableCell className={user.isDepartment ? '' : numberClass}
                                       onClick={() => { clickNumber(user.id, 'start', user.isDepartment) }}>
                                {user.start}
                                {user.hasOwnProperty('startAdd') && user.startAdd > 0 ? `(${user.startAdd})` : null}
                                {user.hasOwnProperty('startAddPlus') && user.startAddPlus > 0 ? `(+${user.startAddPlus})` : null}
                            </TableCell>
                            <TableCell className={user.isDepartment ? '' : numberClass}
                                       onClick={() => { clickNumber(user.id, 'end', user.isDepartment) }}>
                                {user.end}
                                {user.hasOwnProperty('endAdd') && user.endAdd > 0 ? ` (${user.endAdd})` : null}
                                {user.hasOwnProperty('endAddPlus') && user.endAddPlus > 0 ? ` (+${user.endAddPlus})` : null}
                            </TableCell>
                            <TableCell>{user.conversion}</TableCell>
                        </TableRow>
                    )) : reps.map((rep) => (
                        <TableRow key={rep.userId}>
                            <TableCell>{rep.userId}</TableCell>
                            <TableCell className={rep.isDepartment ? '' : numberClass}
                                       onClick={() => { clickNumber(rep.userId, 'start', rep.isDepartment) }}>
                                {rep.start}
                                {rep.hasOwnProperty('startAdd') && rep.startAdd > 0 ? `(${rep.startAdd})` : null}
                                {rep.hasOwnProperty('startAddPlus') && rep.startAddPlus > 0 ? `(+${rep.startAddPlus})` : null}
                            </TableCell>
                            <TableCell className={rep.isDepartment ? '' : numberClass}
                                       onClick={() => { clickNumber(rep.userId, 'end', rep.isDepartment) }}>
                                {rep.end}
                                {rep.hasOwnProperty('endAdd') && rep.endAdd > 0 ? `(${rep.endAdd})` : null}
                                {rep.hasOwnProperty('endAddPlus') && rep.endAddPlus > 0 ? `(+${rep.endAddPlus})` : null}
                            </TableCell>
                            <TableCell>{rep.conversion}</TableCell>
                        </TableRow>
                    )) }
                </TableBody>
            </Table>
        </Fragment>
    )
};

export default Report;