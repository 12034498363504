import React, {useEffect, useState} from 'react';
import {
    makeStyles
} from "@material-ui/core";
import './App.css';
import BX24 from "./bx24";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import Default from "./nav/Default";
import DetailPage from "./ui/DetailPage"; // theme css file



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


function App() {
    const classes = useStyles();
    const [parameters, setParameters] = useState(null);



    useEffect(() => {
        BX24.init(() => {
            const {options} = BX24.placement.info();
            const parameters = options.parameters ? options.parameters : 'default';
            setParameters(parameters);
        })
    }, [])

    let content = null;
    if(parameters === 'default') {
        content = <Default />
    } else if(parameters !== null) {
        content = <DetailPage userId={+parameters.userId} type={parameters.type} report={parameters.report} data={parameters.data} />
    }


    return (
        <div className={classes.root}>
            {content}
        </div>
    )
}

export default App;
