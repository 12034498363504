import React from "react";
import {Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const UsersTable = (props) => {
    // const usersData = [];
    // for(let responsibleId in props.users) {
    //     if(props.users.hasOwnProperty(responsibleId)) {
    //         usersData.push({id: responsibleId, quantity: props.users[responsibleId]})
    //     }
    // }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Сотрудник</TableCell>
                    <TableCell>Отдел</TableCell>
                    <TableCell>Количество сделок</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.users.map((user) => <TableRow key={user.name}>
                    <TableCell>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <Avatar src={user.photo}/>
                            <Box ml={2}>
                                {user.name}
                            </Box>
                        </Grid>
                    </TableCell>
                    <TableCell>{user.department}</TableCell>
                    <TableCell style={{fontSize: "20px"}}>{user.quantity}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    );
};

export default UsersTable;