import React, {useEffect, useState} from "react";
import moment from "moment";
import BX24 from "../bx24";
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Link,
    Typography,
    TablePagination,
    Paper
} from "@material-ui/core";
import {getAllContacts, getUser} from "../services/fetcher";
import {getReportPartTitleByCode, getReportTitleByCode} from "../services/getReport";

const DetailPage = (props) => {
    const [fetchedUsers, setFetchedUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [page, setPage] = useState(0);
    const [elementsOnPage, setElementsOnPage] = useState(20);

    const getDisplayRows = () => {
        const startIndex = page * elementsOnPage;
        return rows.slice(startIndex, startIndex + elementsOnPage);
    };

    const userId = props.userId;
    /**start | end**/
    const type = props.type;
    const report = props.report;
    const data = props.data.data;

    const dataKey = type === 'start' ? 'start_deals' : 'end_deals';
    const rows = data[dataKey].filter((item) => {
        return item.RESERVATION_USERS && Array.isArray(item.RESERVATION_USERS) && item.RESERVATION_USERS.includes(userId.toString());
    }).map((item) => {
        let patient = null;
        if (item.UF_CRM_PATIENT_REAL) {
            patient = item.UF_CRM_PATIENT_REAL;
        } else if (item.CONTACT_IDS && item.CONTACT_IDS.length > 0) {
            patient = item.CONTACT_IDS.find((c) => c.IS_PRIMARY === 'Y').CONTACT_ID;
        }

        return {
            ...item,
            patient
        }
    })

    useEffect(() => {
        getUser(userId)
            .then((userData) => {
                setUser(userData[0]);
            });
    }, [userId])

    useEffect(() => {
        getAllContacts(getDisplayRows().map(item => item.patient))
            .then((users) => {
                setFetchedUsers(users);
            })
    }, [page, elementsOnPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event) => {
        setElementsOnPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    let i = page * elementsOnPage;
    return (
        <Paper style={{margin: 10, padding: 10}}>
            <Typography
                variant={"h5"}>{getReportTitleByCode(report)} {user ? `(${user.LAST_NAME} ${user.NAME})` : ''}</Typography>
            <Typography variant={"h6"}>{getReportPartTitleByCode(report, type)}</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>Сделка</TableCell>
                        <TableCell>Пациент</TableCell>
                        <TableCell>Дата закрытия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getDisplayRows().map((item, index) => {
                        i++;
                        const fetchedUser = fetchedUsers.find((contact) => +item.patient > 0 && +contact.ID === +item.patient);
                        const rootAddress = 'https://' + BX24.getDomain()

                        const dealLink = rootAddress + `/crm/deal/details/${item.ID}/`;
                        const contactLink = rootAddress + `/crm/contact/details/${item.patient}/`;

                        return (
                            <TableRow key={index}>
                                <TableCell>{i}</TableCell>
                                <TableCell><Link target={"_blank"} href={dealLink}>{item.TITLE}</Link></TableCell>
                                <TableCell href={contactLink}>
                                    <Link target={"_blank"} href={contactLink}>
                                        {fetchedUser ? `${fetchedUser.LAST_NAME} ${fetchedUser.NAME}` : item.patient}
                                    </Link>
                                </TableCell>
                                <TableCell>{moment(item.CLOSEDATE).format('DD.MM.YYYY')}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={elementsOnPage}
                page={page}
                onChangePage={handleChangePage}
                lang={"ru"}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default DetailPage;