import BX24 from "../bx24";

export const divideArrayByQuantity = (data, quantity) => {
    const res = [];
    while(data.length > 0) {
        res.push(data.splice(0, quantity));
    }

    return res;
}

export const getAllContacts = async (ids) => {
    const idsDivided = divideArrayByQuantity(ids, 50);
    const batchDivided = divideArrayByQuantity(idsDivided, 50);
    let results = [];
    for(let batchIds of batchDivided) {
        const users2500Max = await new Promise((resolve, reject) => {
            BX24.callBatch(batchIds.map((localIds) => {
                return {
                    method: 'crm.contact.list',
                    params: {
                        filter: { ID: localIds }
                    }
                }
            }), (data) => {
                let users = [];
                for(let curUsers of data) {
                    users = users.concat(curUsers.data())
                }
                resolve(users);
            })
        })
        results = results.concat(users2500Max);
    }

    return results;
}

export const getUser = (id) => {
    return new Promise((resolve) => {
        BX24.callMethod('user.get', {id: id}, (ans) => [
            resolve(ans.data())
        ])
    })
}