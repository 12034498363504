import React, {Fragment} from "react";
import Typography from "@material-ui/core/Typography";

const Number = (props) => {
    const val = props.val;
    const prev = props.prev;
    const diff = val - prev;
    const className = diff > 0 ? "Number-Diff Number-Diff_plus" : (diff < 0 ? "Number-Diff Number-Diff_minus" : "Number-Diff Number-Diff_null");

    return (
        <Fragment>
            <span className="Number">
                <span>
                    {val} <Typography  variant="body2" className={className} component="span" >{diff <= 0 ? diff : '+' + diff}</Typography>
                </span>
                <span className="Number-Hint">{props.hint}</span>
            </span>
        </Fragment>
    )
};

export default Number;